import React, { Component } from 'react'
// import { Link } from 'gatsby'
// import { Nav, Image, Dropdown } from 'react-bootstrap'
import SideBar from 'react-sidebar'
import './menu.css'
import { Link } from 'gatsby'

export default class Menu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sidebarOpen: false,
      sidebarpullRight: true,
    }
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this)
  }

  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open })
  }
  render() {
    return (
      <SideBar
        sidebar={
          <div className="navigation">
            <Link to="/">
              <img
                className="img-fluid"
                src="/img/inline-logo.png"
                alt="logo"
              />
            </Link>
            <ul className="list-unstyled d-flex main-nav flex-column mb-0">
              <li>
                <Link to="/">Home </Link>
              </li>
              <li>
                <Link to="/about-colorado-springs/">
                  About Colorado Springs
                </Link>
              </li>
              <li>
                <Link to="/testimonials/">Testimonials</Link>
              </li>
              <li>
                <Link to="/physicians-medical/">Physician</Link>
              </li>
              <li>
                <Link to="/the-buffini-difference/">
                  The Buffini Difference
                </Link>
              </li>
              <li>
                <a
                  href="https://www.buffini.com/products/bold-predictions/bold-predictions-2025-watch.aspx"
                  target="blank_"
                  rel="noreferrer"
                >
                  Learn About Bold Predictions for 2025
                </a>
              </li>
              <li>
                <Link to="/funny-client-stories">Funny Client Stories </Link>
              </li>
              <li className="list-head">Take Action Now</li>
              <li>
                <Link to="/property-listings/">Listings</Link>
              </li>
              <li>
                <Link to="/buy/">Find A Home</Link>
              </li>
              <li>
                <Link to="/selling-process/">Sell</Link>
              </li>
              <li>
                <Link to="/finance">Finance</Link>
              </li>
              <li>
                <Link to="/military">Military</Link>
              </li>
              <li className="list-head">Brian Boals' Team</li>
              {/* <li>
                <Link to="/preferred-vendors">Preferred Vendors</Link>
              </li> */}
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/fall-roundup">Fall Round Up</Link>
              </li>
              <li>
                <Link to="/news">Resources</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
            <ul className="list-unstyled d-flex flex-column justify-content-start mt-4 side-info">
              <li className="mb-3">
                <a href="tel:719-459-9955" className="text-default hvr-shadow">
                  (719) 459-9955
                </a>
              </li>
              <li>
                <Link
                  to="/property-listings"
                  className="alink hvr-shadow text-white text-uppercase"
                >
                  Find Dream Home
                </Link>
              </li>
            </ul>
          </div>
        }
        open={this.state.sidebarOpen}
        onSetOpen={this.onSetSidebarOpen}
        pullRight={this.state.sidebarpullRight}
        styles={{
          sidebar: {
            background: '#ffffff',
            width: '300px',
            position: 'fixed',
            zIndex: '9999',
            height: '100%',
          },
          root: { position: 'relative', overflow: 'visible' },
          overlay: { backgroundColor: 'rgba(0, 0, 0, 0.65)' },
          content: {
            position: 'relative',
            overflow: 'visible',
            cursor: 'pointer',
          },
        }}
      >
        <div
          className="nav_content"
          onClick={() => this.onSetSidebarOpen(true)}
        >
          <h5 className="float-right nav_handle mb-0 ">
            <img src="/img/bars.svg" alt="bar" />
          </h5>
        </div>
      </SideBar>
    )
  }
}
